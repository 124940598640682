import { motion } from 'framer-motion';
import Image from 'next/image';
import clsx from 'clsx';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import Logo from 'assets/images/tojjar-icon-dark.svg';
import { Button } from 'components/shared/button/button';
import { usePageType } from 'hooks/usePageType/usePageType';
import { useScrollToPageSection } from 'hooks/useScrollToPageSection/useScrollToPageSection';

import styles from './hero.module.scss';

export const Hero = () => {
  const { isBooths, isApps } = usePageType();
  const { formatMessage, locale } = useLocale();
  const { scrollToRegister } = useScrollToPageSection();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <motion.div
          transition={{ delay: 1.2 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={styles.callToAction}
        >
          <div className={styles.tojjarLogo}>
            <Image
              src={Logo}
              alt={
                isBooths
                  ? formatMessage({
                      id: AppMessages['hero.booths.booths'],
                    })
                  : formatMessage({
                      id: AppMessages['hero.apps.apps'],
                    })
              }
            />
          </div>
          <h1 className={styles.header}>
            {isBooths
              ? formatMessage({
                  id: AppMessages['hero.booths.heading'],
                })
              : formatMessage({
                  id: AppMessages['hero.apps.heading'],
                })}
          </h1>
          <p className={styles.content}>
            {isBooths
              ? formatMessage({
                  id: AppMessages['hero.booths.content'],
                })
              : formatMessage({
                  id: AppMessages['hero.apps.content'],
                })}
          </p>
          <Button size="big" variant="primary" onClick={scrollToRegister}>
            {isBooths
              ? formatMessage({
                  id: AppMessages['hero.booths.button'],
                })
              : formatMessage({
                  id: AppMessages['hero.apps.button'],
                })}
          </Button>
        </motion.div>

        <motion.div
          transition={{ type: 'spring', delay: 0.5, stiffness: 170, damping: 16 }}
          initial={{ x: locale === 'ar' ? '100vw' : '-100vw' }}
          animate={{ x: '0' }}
          className={clsx(isBooths ? styles.booth : styles.apps)}
          style={isBooths ? { transform: 'scale(-1, 1)' } : {}}
        >
          <div
            className={clsx(styles.imgInner, {
              [styles.boothsImg]: isBooths,
              [styles.appsImg]: isApps,
            })}
          />
        </motion.div>

        <motion.div
          transition={{ delay: 1 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={clsx(styles.serviceNameContainer, { [styles.boothService]: isBooths })}
        >
          {locale === 'en' &&
            formatMessage({
              id: AppMessages['hero.tojjar'],
            })}{' '}
          <span className={styles.serviceName}>
            {isBooths
              ? formatMessage({
                  id: AppMessages['hero.booths.booths'],
                })
              : formatMessage({
                  id: AppMessages['hero.apps.apps'],
                })}
          </span>
          <svg
            width="116"
            height="102"
            viewBox="0 0 116 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.serviceNamePath}
          >
            <path
              d="M104.279 67.7013C90.7351 50.2584 51.0763 17.0136 0.792825 23.5781"
              stroke="#3B5B70"
              strokeWidth="2"
              strokeDasharray="10 10"
            />
            <circle
              r="4.5"
              transform="matrix(-0.971226 0.238161 0.238161 0.971226 109.347 73.6663)"
              stroke="#3B5B70"
              strokeWidth="2"
            />
          </svg>
        </motion.div>
      </div>
    </div>
  );
};
